import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NorelaButton from "../../components/Buttons/NorelaButton";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { PageURL } from "../../types/pages";
import { useResetOrder } from "../../hooks/useResetOrder";
import BetaLogo from "../../assets/images/beta-logo.svg";
import PrecompoPicture from "../../assets/images/introduction/PrecompoPicture.svg";
import FreecompoPicture from "../../assets/images/introduction/FreecompoPicture.svg";
import { useRef } from "react";

const SelectPathContent = () => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const { updateHistory, setNeedHelp, sessionInfo } = useSessionContext();
  const onClick = (link: PageURL) => {
    updateHistory(link);
    navigate(link);
  };
  const { partialResetOrder } = useResetOrder();
  const { track } = useSegment();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = useRef(null);

  function newOrder() {
    const text = sessionInfo?.needHelp ? "Don't need help" : "Need help";
    track(text);
    partialResetOrder();
    setNeedHelp(!sessionInfo?.needHelp);
    if (sessionInfo?.needHelp === false) {
      switch (queryParameters.get("operation")) {
        case "professionnel":
          onClick(PageURL.ONBOARDING_PRO);
          break;
        case "grand-public":
          onClick(PageURL.ONBOARDING_PUBLIC);
          break;
        case "street-marketing":
          onClick(PageURL.ONBOARDING_STREET_MARKETING);
          break;
        case "animation-promotionnelle":
          onClick(PageURL.ONBOARDING_ANIMATION_PROMO);
          break;
        case "animation-evenementielle":
          onClick(PageURL.ONBOARDING_ANIMATION_EVENT);
          break;
        default:
          onClick(PageURL.SELECT_PATH);
          break;
      }
    } else onClick(PageURL.CALENDAR);
  }
  function continueOrder() {
    if (sessionInfo?.needHelp === true) {
      switch (queryParameters.get("operation")) {
        case "professionnel":
          onClick(PageURL.ONBOARDING_PRO);
          break;
        case "grand-public":
          onClick(PageURL.ONBOARDING_PUBLIC);
          break;
        case "street-marketing":
          onClick(PageURL.ONBOARDING_STREET_MARKETING);
          break;
        case "animation-promotionnelle":
          onClick(PageURL.ONBOARDING_ANIMATION_PROMO);
          break;
        case "animation-evenementielle":
          onClick(PageURL.ONBOARDING_ANIMATION_EVENT);
          break;
        default:
          onClick(PageURL.SELECT_PATH);
          break;
      }
    } else onClick(PageURL.CALENDAR);
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      height="90vh"
      mt="-2%"
    >
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={ref}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Vous avez une commande en cours, en continuant sur{" "}
            {sessionInfo?.needHelp
              ? '"Pré-composition des équipes"'
              : '"Composition libre des équipes"'}
            , vous acceptez de la réinitialiser.
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogFooter>
            <Button color="red" backgroundColor="white" onClick={newOrder}>
              Nouvelle commande
            </Button>
            <NorelaButton onClick={continueOrder}>
              Reprendre la commande
            </NorelaButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Image
        src={BetaLogo}
        maxWidth="770px"
        maxHeight="235px"
        alignSelf="top"
      />
      <Heading fontWeight="700" fontSize="36px" lineHeight="57.6px">
        Choisissez votre niveau d’automatisation
      </Heading>
      <Flex gap="100px" direction="row">
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
          height="500px"
          width="420px"
        >
          <Image
            src={PrecompoPicture}
            maxWidth="270px"
            maxHeight="150px"
            alignSelf="top"
          />
          <Heading fontWeight="700" fontSize="24px" lineHeight="36px">
            Laissez-vous guider et gagnez du temps !
          </Heading>
          <Text
            fontWeight="500px"
            fontSize="20px"
            lineHeight="30px"
            height="100px"
          >
            Répondez à quelques questions et notre algorithme configurera
            automatiquement vos équipes. Vous pourrez toujours affiner !
          </Text>
          <NorelaButton
            disabled={queryParameters.get("operation") === "road-show"}
            onClick={() => {
              if (sessionInfo.needHelp === false) {
                onOpen();
              } else {
                track("Need help");
                setNeedHelp(true);
                switch (queryParameters.get("operation")) {
                  case "professionnel":
                    onClick(PageURL.ONBOARDING_PRO);
                    break;
                  case "grand-public":
                    onClick(PageURL.ONBOARDING_PUBLIC);
                    break;
                  case "street-marketing":
                    onClick(PageURL.ONBOARDING_STREET_MARKETING);
                    break;
                  case "animation-promotionnelle":
                    onClick(PageURL.ONBOARDING_ANIMATION_PROMO);
                    break;
                  case "animation-evenementielle":
                    onClick(PageURL.ONBOARDING_ANIMATION_EVENT);
                    break;
                  default:
                    onClick(PageURL.SELECT_PATH);
                    break;
                }
              }
            }}
          >
            Pré-composition des équipes{" "}
            {queryParameters.get("operation") !== "road-show"
              ? ""
              : "(Coming soon)"}
          </NorelaButton>
        </Flex>

        <Flex
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
          height="500px"
          width="420px"
        >
          <Image
            src={FreecompoPicture}
            maxWidth="270px"
            maxHeight="150px"
            alignSelf="top"
          />
          <Heading fontWeight="700" fontSize="24px" lineHeight="36px">
            Créez vous-même votre opération de A à Z
          </Heading>
          <Text
            fontWeight="500px"
            fontSize="20px"
            lineHeight="30px"
            height="100px"
          >
            Accédez directement à tous les types de profils proposé par
            l’agence.
          </Text>
          <NorelaButton
            onClick={() => {
              if (sessionInfo.needHelp === true) {
                onOpen();
              } else {
                track("Don't need help");
                setNeedHelp(false);
                onClick(PageURL.CALENDAR);
              }
            }}
          >
            Composition libre des équipes
          </NorelaButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SelectPathContent;
