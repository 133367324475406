import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { colors } from "../../ui/theme";
import { Elements } from "./ChooseOperationTypeContent";
import { useNavigate } from "react-router-dom";
import { useSegment } from "../../context/SegmentContext";

const OperationTypeCard = ({
  title,
  imagePath,
  desc,
  elements,
}: {
  title: string;
  imagePath: string;
  desc: string;
  elements: Elements[];
}) => {
  const navigate = useNavigate();
  const { track } = useSegment();
  let text:
    | "Select Personnel d'Accueil"
    | "Select Personnel d'Animation"
    | "Select Distribution & Echantillonage";

  switch (title) {
    case "Personnel d’Accueil":
      text = "Select Personnel d'Accueil";
      break;
    case "Personnel d’Animation":
      text = "Select Personnel d'Animation";
      break;
    case "Distribution & Echantillonage":
      text = "Select Distribution & Echantillonage";
      break;
  }

  return (
    <Flex
      overflow="hidden"
      _hover={{
        "& .newHover": {
          top: 0,
        },
      }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="300px"
      height="330px"
      borderRadius="24px"
      border="1px"
      borderColor="#D9D9D9"
      padding="12px"
      gap="15px"
      backgroundColor="transparent"
      position="relative"
    >
      <Heading fontWeight="700" fontSize="18px" lineHeight="31.92px">
        {title}
      </Heading>
      <Image
        alt={"Operation" + title + " picture"}
        maxWidth="200px"
        maxHeight="200px"
        src={imagePath}
      />
      <Text>{desc}</Text>
      <Flex
        transition="0.3s"
        className="newHover"
        top="-150%"
        position="absolute"
        backgroundColor="white"
        height="100%"
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {elements.map((element) => {
          return (
            <Button
              key={element.title}
              onClick={() => {
                navigate("/select-path?operation=" + element.slug);
                track(text);
              }}
              margin="8px 5px"
              transition="0.5s"
              _hover={{
                backgroundColor: colors.secondaryFontColor.lightMode,
                "& p": {
                  color: colors.white,
                },
              }}
              whiteSpace="break-spaces"
              border="1px solid #033349"
              borderRadius="8px"
              padding="8px 12px"
              backgroundColor="transparent"
            >
              <Text fontWeight="500">{element.title}</Text>
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default OperationTypeCard;
