import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import WelcomePicture from "../../assets/images/introduction/WelcomePicture.png";
import AnimationPicture from "../../assets/images/introduction/AnimationPicture.png";
import SharingPicture from "../../assets/images/introduction/SharingPicture.png";
import BetaLogo from "../../assets/images/beta-logo.svg";
import OperationTypeCard from "./OperationTypeCard";

export type Elements = {
  slug: string;
  title: string;
};

const ChooseOperationTypeContent = () => {
  const needs = [
    {
      title: "Personnel d’Accueil",
      image: WelcomePicture,
      desc: "Accueillez vos invités et valorisez l’image de votre événement",
      elements: [
        {
          slug: "professionnel",
          title: "Professionnel (BtoB)",
        },
        {
          slug: "grand-public",
          title: "Grand Public",
        },
      ],
    },
    {
      title: "Personnel d’Animation",
      image: AnimationPicture,
      desc: "Animez votre événement et divertissez vos invités",
      elements: [
        {
          slug: "animation-promotionnelle",
          title: "Animation Magasin",
        },
        {
          slug: "animation-evenementielle",
          title: "Animation Événementielle",
        },
        {
          slug: "road-show",
          title: "Road Show",
        },
      ],
    },
    {
      title: "Distribution & Echantillonage",
      image: SharingPicture,
      desc: "Faites connaître votre marque, tester ou découvrir votre produit",
      elements: [
        {
          slug: "street-marketing",
          title: "Street Marketing",
        },
      ],
    },
  ];

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      height="90vh"
      mt="-2%"
    >
      <Image
        src={BetaLogo}
        maxWidth="770px"
        maxHeight="235px"
        alignSelf="top"
      />
      <Box>
        <Heading fontWeight="700" fontSize="36px" lineHeight="57.6px">
          Imaginez votre opération, nous la réalisons !
        </Heading>
        <Heading fontWeight="400" fontSize="16px" lineHeight="36px">
          Sélectionnez votre besoin pour votre événement
        </Heading>
      </Box>
      <Flex gap="30px">
        {needs.map((need, index) => (
          <OperationTypeCard
            key={index}
            title={need.title}
            imagePath={need.image}
            desc={need.desc}
            elements={need.elements}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default ChooseOperationTypeContent;
