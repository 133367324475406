import * as Sentry from "@sentry/react";
import { Box, ChakraProvider, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import characterMobile from "./assets/images/mobile/characterMobile.png";
import screenComputer from "./assets/images/mobile/screenComputer.png";
import Header from "./components/Header";
import { LogoEnorelaBeta } from "./components/Icons/LogoEnorelaBeta";
import { HelpType, useHelpContext } from "./context/HelpContext";
import { useSegment } from "./context/SegmentContext";
import { useProgressBar } from "./hooks/useProgressBar";
import About from "./pages/About/index";
import CGV from "./pages/CGV/CGV";
import Calendar from "./pages/Calendar/Calendar";
import ContactFormPage from "./pages/ContactForm/ContactFormPage";
import Dressing from "./pages/Dressing/Dressing";
import OnboardingAnimationEvent from "./pages/Onboarding/OnboardingAnimationEvent";
import OnboardingAnimationPromo from "./pages/Onboarding/OnboardingAnimationPromo";
import OnboardingDetails from "./pages/Onboarding/OnboardingDetails";
import OnboardingEventOptions from "./pages/Onboarding/OnboardingEventOptions";
import OnboardingFlyers from "./pages/Onboarding/OnboardingFlyers";
import OnboardingProfessionnel from "./pages/Onboarding/OnboardingProfessionnel";
import OnboardingPublic from "./pages/Onboarding/OnboardingPublic";
import OnboardingReception from "./pages/Onboarding/OnboardingReception";
import OnboardingRoadshow from "./pages/Onboarding/OnboardingRoadshow";
import OnboardingSellingPoints from "./pages/Onboarding/OnboardingSellingPoints";
import OnboardingSize from "./pages/Onboarding/OnboardingSize";
import OnboardingStreetMarketing from "./pages/Onboarding/OnboardingStreetMarketing";
import OnboardingTransport from "./pages/Onboarding/OnboardingTransport";
import Options from "./pages/Options/Options";
import Order from "./pages/Order/Order";
import Recapitulatif from "./pages/Recapitulatif/Recapitulatif";
import Validation from "./pages/Validation/Validation";
import "./styles/fonts.css";
import "./styles/global.css";
import { PageURL } from "./types/pages";
import { customTheme } from "./ui/theme";
import useWindowDimensions from "./utils/utils";
import SelectPath from "./pages/Introduction/SelectPath";
import ChooseOperationType from "./pages/Introduction/ChooseOperationType";

Sentry.init({
  dsn: "https://9322d6e400f2ee09f9d3df4819a5f077@o4507843369304064.ingest.de.sentry.io/4507843371466832",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/app\.e-norela\.com/,
    /^https:\/\/e-norela-staging\.vercel\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const MIN_HEIGHT = 640;
const MIN_WIDTH = 1100;

function App() {
  const { load } = useSegment();
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { height, width } = useWindowDimensions();
  const { page, track } = useSegment();
  const useUpdateProgressBar = useProgressBar();
  useUpdateProgressBar();
  const location = useLocation();

  const { setHelp } = useHelpContext();

  useEffect(() => {
    setHelp(null);
    const pathArray = location.pathname.split("/").filter(Boolean);
    const formattedPath = pathArray
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join("-");
    page(formattedPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [zoomWarning, setZoomWarning] = useState(false);

  useEffect(() => {
    if (window.devicePixelRatio * 100 > 140 && zoomWarning) {
      // track("Waaa le zoom", { zoom: window.devicePixelRatio * 100 });
      setHelp(HelpType.ZOOM);
      setZoomWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.devicePixelRatio]);

  if (width < MIN_WIDTH * 0.7 || height < MIN_HEIGHT * 0.7) {
    track("Screen too small");
    return (
      <Flex
        className="App"
        h="100vh"
        direction={"column"}
        justifyContent="space-between"
      >
        <LogoEnorelaBeta
          cursor="pointer"
          width="335px"
          height="105px"
          mt="3rem"
          alignSelf="top"
          mx="auto"
        />
        <Flex m="1rem">
          <Header
            title={
              "Cette page s’afficherait beaucoup mieux sur un ordinateur !"
            }
          ></Header>
        </Flex>
        <Image src={screenComputer} mt="1rem" ml="auto" mr="auto" w="80%" />
        <Image src={characterMobile} w="90%" display="flex" ml="auto" />
      </Flex>
    );
  }
  if (width < MIN_WIDTH * 0.9 || height < MIN_HEIGHT * 0.9) {
    track("Screen too small");
    return (
      <Flex className="App" direction={"column"} justifyContent="space-between">
        <LogoEnorelaBeta
          cursor="pointer"
          width="335px"
          height="105px"
          mt="3rem"
          alignSelf="top"
          mx="auto"
        />
        <Flex m="4rem 1rem 3rem 5rem">
          <Header
            title={
              "Pour pouvoir afficher cette page correctement, veuillez réduire le zoom !"
            }
          />
        </Flex>
        <Box
          ml="10rem"
          w="350px"
          border="1px solid black"
          borderRadius="12px"
          p="1rem"
        >
          <Text fontSize="20px" fontWeight="bold" textAlign="start">
            Voici comment régler ce problème:
          </Text>
          <Text fontSize="20px" fontWeight="bold" textAlign="start">
            Sur Mac: cmd + - <br />
            Sur Windows: ctrl + - <br />
            Sur Linux: ctrl + - <br />
          </Text>
        </Box>
      </Flex>
    );
  }
  return (
    <div className="App">
      <ChakraProvider theme={customTheme}>
        <Routes>
          <Route path={PageURL.HOME} element={<ChooseOperationType />} />
          <Route path={PageURL.SELECT_PATH} element={<SelectPath />} />
          <Route
            path={PageURL.ONBOARDING_ANIMATION_EVENT}
            element={<OnboardingAnimationEvent />}
          />
          <Route
            path={PageURL.ONBOARDING_ANIMATION_PROMO}
            element={<OnboardingAnimationPromo />}
          />
          <Route
            path={PageURL.ONBOARDING_PRO}
            element={<OnboardingProfessionnel />}
          />
          <Route
            path={PageURL.ONBOARDING_PUBLIC}
            element={<OnboardingPublic />}
          />
          <Route
            path={PageURL.ONBOARDING_ROADSHOW}
            element={<OnboardingRoadshow />}
          />
          <Route
            path={PageURL.ONBOARDING_STREET_MARKETING}
            element={<OnboardingStreetMarketing />}
          />
          <Route
            path={PageURL.ONBOARDING_TAILLE}
            element={<OnboardingSize />}
          />
          <Route
            path={PageURL.ONBOARDING_TRANSPORT}
            element={<OnboardingTransport />}
          />
          <Route
            path={PageURL.ONBOARDING_EVENT_OPTIONS}
            element={<OnboardingEventOptions />}
          />
          <Route
            path={PageURL.ONBOARDING_FLYERS}
            element={<OnboardingFlyers />}
          />
          <Route
            path={PageURL.ONBOARDING_POINTS_DE_VENTE}
            element={<OnboardingSellingPoints />}
          />
          <Route
            path={PageURL.ONBOARDING_RECEPTION}
            element={<OnboardingReception />}
          />
          <Route
            path={PageURL.ONBOARDING_DETAILS}
            element={<OnboardingDetails />}
          />
          <Route path={PageURL.DRESSING} element={<Dressing />} />
          <Route path={PageURL.OPTIONS} element={<Options />} />
          <Route path={PageURL.RECAPITULATIF} element={<Recapitulatif />} />
          <Route path={PageURL.VALIDATION} element={<Validation />} />
          <Route path={PageURL.ORDER} element={<Order />} />
          <Route path={PageURL.CVG} element={<CGV />} />
          <Route path={PageURL.ABOUT} element={<About />} />
          <Route path={PageURL.CALENDAR} element={<Calendar />} />
          <Route path={PageURL.CONTACT} element={<ContactFormPage />} />
          <Route path="*" element={<ChooseOperationType />} />
        </Routes>
      </ChakraProvider>
    </div>
  );
}

export default App;
