export enum PageURL {
  HOME = "/",
  SELECT_PATH = "/select-path",
  ONBOARDING = "/onboarding",
  ONBOARDING_PUBLIC = "/onboarding/grand-public",
  ONBOARDING_PRO = "/onboarding/professionnel",
  ONBOARDING_STREET_MARKETING = "/onboarding/street-marketing",
  ONBOARDING_ANIMATION_PROMO = "/onboarding/animation-promotionnelle",
  ONBOARDING_ANIMATION_EVENT = "/onboarding/animation-evenementielle",
  ONBOARDING_ROADSHOW = "/onboarding/road-show",
  ONBOARDING_TAILLE = "/onboarding/taille",
  ONBOARDING_TRANSPORT = "/onboarding/transport",
  ONBOARDING_EVENT_OPTIONS = "/onboarding/options",
  ONBOARDING_FLYERS = "/onboarding/flyers",
  ONBOARDING_POINTS_DE_VENTE = "/onboarding/points-de-vente",
  ONBOARDING_RECEPTION = "/onboarding/reception",
  ONBOARDING_DETAILS = "/onboarding/details",
  DRESSING = "/dressing",
  OPTIONS = "/options",
  RECAPITULATIF = "/recapitulatif",
  ORDER = "/order",
  VALIDATION = "/validation",
  CVG = "/cgv",
  ABOUT = "/about",
  CALENDAR = "/calendar",
  CONTACT = "/contact",
}
