import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  Text,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HelpType, useHelpContext } from "../../context/HelpContext";
import { useOnboardingContext } from "../../context/OnboardingContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import { PersonnelType, useUserContext } from "../../context/UserContext";
import DressingTabMenu from "../../modules/Dressing/components/DressingTabMenu";
import { EventCategory, EventType } from "../../modules/Onboarding/EventTypes";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import BottomBlock from "../BottomActionsBlock/BottomBlock";
import GenericDressingSelectionCard from "../GenericDressingSelectionCard";
import PersonalOutfitMenu from "../PersonalOutfitMenu/PersonalCustomMenu";
import { Icons } from "../PicturesVariant/PicturesVariant";
import ProfessionalOutfitMenu from "../ProfessionalOutfitMenu/ProfessionalOutfitMenu";
import MakeupItemCard from "./MakeupItemCard";
import PrintedOutfitMenu from "./PrintedOutfitMenu";
import PrintedOutfitSubMenu from "./PrintedOutfitSubMenu";
import ProvidedOutfitMenu from "./ProvidedOutfitMenu";
import { getDayList } from "../../utils/utils";

export enum OutfitType {
  PROFESSIONAL_OUTFIT = "Tenue professionnelle",
  PERSONAL_OUTFIT = "Tenue personnelle",
  PROVIDED_OUTFIT = "Je fournis les tenues",
  PRINTED_OUTFIT = "Tenue imprimée",
}

export interface DressCode {
  outfitType?: OutfitType;
  professionalOutfit?: string;
  personalOutfit?: {
    generic?: string;
    custom?: {
      ensemble?: string;
      blazer?: boolean;
      top?: string;
      topColor?: string;
      bottom?: string;
      bottomColor?: string;
      shoes?: string;
      shoesColor?: string;
    };
  };
  providedOutfit?: {
    providedTop?: boolean;
    providedBottom?: boolean;
    providedShoes?: boolean;
    blazer?: boolean;
    top?: string;
    topColor?: string;
    bottom?: string;
    bottomColor?: string;
    shoes?: string;
    shoesColor?: string;
  };
  printedOutfit?: {
    printedCap?: boolean;
    printedTshirt?: boolean;
    printedWindbreaker?: boolean;
    tshirtCount?: number;
    logoFormat?: string;
    blazer?: boolean;
    top?: string;
    topColor?: string;
    bottom?: string;
    bottomColor?: string;
    shoes?: string;
    shoesColor?: string;
    color?: string;
  };
  presentation?: {
    hair?: string;
    nails?: string;
    lipstick?: string;
  };
}

export default function DressingBlock() {
  const { userInfos, setUserInfos } = useUserContext();

  const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;
  const amountOfPersonels =
    (selectDePersonnel &&
      Object.keys(selectDePersonnel).reduce((acc, personKey) => {
        return acc + selectDePersonnel[personKey as PersonnelType]!.count;
      }, 0)) ||
    1;
  const totalEventDays = getDayList(userInfos.calendarInfo!)?.length ?? 1;

  const [valid, setValid] = useState<boolean>(false);
  // const [dressCode, setDressCode] = useState<DressCode>({});
  const [isOpenDetailedMenu, setOpenDetailedMenu] = useState<boolean>(true);
  const [isOpenPresentationModal, setOpenPresentationModal] =
    useState<boolean>(false);

  const { eventCategory, eventType, dressCode, setDressCode } =
    useOnboardingContext();
  let availableHairStyles: string[] = [];
  if (eventType === EventType.STREET_MARKETING) {
    availableHairStyles = ["Détachés", "Queue de cheval"];
  } else {
    availableHairStyles = [
      "Détachés",
      "Chignon Haut",
      "Chignon Bas",
      "Queue de cheval",
    ];
  }

  //---HELP BUBBLE---
  const { setHelp } = useHelpContext();
  const [showHelpProvided, setShowHelpProvided] = useState(true);
  const [showHelpPrinted, setShowHelpPrinted] = useState(true);

  useEffect(() => {
    if (eventType !== EventType.ACCUEIL) setHelp(HelpType.PARAPLUIE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      dressCode?.outfitType === OutfitType.PROVIDED_OUTFIT &&
      showHelpProvided
    ) {
      setHelp(HelpType.PROVIDED_OUTFIT);
      setShowHelpProvided(false);
    } else if (
      dressCode?.outfitType === OutfitType.PRINTED_OUTFIT &&
      showHelpPrinted
    ) {
      setHelp(HelpType.PRINTED_ELEMENT);
      setShowHelpPrinted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dressCode]);

  //---UPDATE USERINFOS---
  const canValidate = (): boolean => {
    if (dressCode?.outfitType === OutfitType.PROVIDED_OUTFIT) {
      const selection = dressCode.providedOutfit;
      if (
        (selection?.providedTop || (selection?.top && selection?.topColor)) &&
        (selection?.providedBottom ||
          (selection?.bottom && selection?.bottomColor)) &&
        (selection?.providedShoes ||
          (selection?.shoes && selection?.shoesColor)) &&
        (selection?.providedTop ||
          selection?.providedBottom ||
          selection?.providedShoes)
      )
        return true;
    }
    if (dressCode?.outfitType === OutfitType.PROFESSIONAL_OUTFIT) {
      if (dressCode?.professionalOutfit) {
        return true;
      }
    }
    if (dressCode?.outfitType === OutfitType.PERSONAL_OUTFIT) {
      const selection = dressCode.personalOutfit;
      if (
        selection?.generic ||
        (selection?.custom &&
          (selection?.custom?.ensemble ||
            (selection?.custom?.top && selection?.custom?.bottom)) &&
          selection?.custom?.shoes)
      ) {
        return true;
      }
    }
    if (dressCode?.outfitType === OutfitType.PRINTED_OUTFIT) {
      const selection = dressCode.printedOutfit;
      if (
        (selection?.printedCap ||
          selection?.printedTshirt ||
          selection?.printedWindbreaker) &&
        selection?.logoFormat &&
        selection?.color &&
        selection?.bottom &&
        selection?.shoes &&
        (selection?.top || selection?.printedTshirt)
      ) {
        return true;
      }
    }
    return false;
  };

  const isPresentationValid = (): boolean => {
    if (
      dressCode?.presentation?.hair &&
      dressCode?.presentation?.nails &&
      dressCode?.presentation?.lipstick
    ) {
      return true;
    }
    return false;
  };
  const enableProfessionalOutfit = (): boolean => {
    if (eventCategory === EventCategory.STREET_MARKETING) return false;
    return true;
  };
  const enablePersonalOutfit = (): boolean => {
    return true;
  };
  const enableProvidedOutfit = (): boolean => {
    return true;
  };
  const enablePrintedOutfit = (): boolean => {
    return true;
  };

  useEffect(() => {
    setValid(canValidate());
    if (
      dressCode?.presentation?.hair &&
      dressCode?.presentation.nails &&
      dressCode?.presentation.lipstick
    )
      setUserInfos({
        ...userInfos,
        outfitInfos: {
          ...dressCode,
        },
      });
    else
      setUserInfos({
        ...userInfos,
        outfitInfos: {
          ...dressCode,
          presentation: {
            hair: undefined,
            nails: undefined,
            lipstick: undefined,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dressCode]);

  //-------------MAIN MENU--------------
  const activeProfessionalOutfit = () => {
    setDressCode({
      ...dressCode,
      outfitType: OutfitType.PROFESSIONAL_OUTFIT,
      professionalOutfit: undefined,
      personalOutfit: undefined,
      printedOutfit: undefined,
      presentation: {
        hair: "Détachés",
        nails: "None",
        lipstick: "None",
      },
    });
  };
  const activePersonalOutfit = () => {
    setDressCode({
      ...dressCode,
      outfitType: OutfitType.PERSONAL_OUTFIT,
      professionalOutfit: undefined,
      personalOutfit: undefined,
      printedOutfit: undefined,
      presentation: {
        hair: "Détachés",
        nails: "None",
        lipstick: "None",
      },
    });
  };
  const activePrintedOutfit = () => {
    setDressCode({
      ...dressCode,
      outfitType: OutfitType.PRINTED_OUTFIT,
      professionalOutfit: undefined,
      personalOutfit: undefined,
      printedOutfit: {
        printedCap: undefined,
        printedTshirt: undefined,
        printedWindbreaker: undefined,
        logoFormat: undefined,
        color: undefined,
        bottom: undefined,
        shoes: undefined,
      },
      presentation: {
        hair: "Détachés",
        nails: "None",
        lipstick: "None",
      },
    });
  };
  const activeProvidedOutfit = () => {
    setDressCode({
      ...dressCode,
      outfitType: OutfitType.PROVIDED_OUTFIT,
      professionalOutfit: undefined,
      personalOutfit: undefined,
      printedOutfit: undefined,
      providedOutfit: {
        providedTop: undefined,
        providedBottom: undefined,
        providedShoes: undefined,
        blazer: undefined,
        top: undefined,
        topColor: undefined,
        bottom: undefined,
        bottomColor: undefined,
        shoes: undefined,
        shoesColor: undefined,
      },
      presentation: {
        hair: "Détachés",
        nails: "None",
        lipstick: "None",
      },
    });
  };

  //---PRESENTATION---
  const setHair = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      presentation: {
        ...dressCode?.presentation,
        hair: value,
      },
    });
  };
  const setNails = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      presentation: {
        ...dressCode?.presentation,
        nails: value,
      },
    });
  };
  const setLipstick = (value: string | undefined) => {
    setDressCode({
      ...dressCode,
      presentation: {
        ...dressCode?.presentation,
        lipstick: value,
      },
    });
  };

  //------OPEN PERSONAL CUSTOM MENU--------
  const activePersonalCustomMenu = (isOpen: boolean) => {
    setOpenDetailedMenu(isOpen);
    if (isOpen) {
      setDressCode({
        ...dressCode,
        personalOutfit: undefined,
      });
    } else {
      setDressCode({
        ...dressCode,
        personalOutfit: undefined,
      });
    }
  };

  const OpenPresentationChoice = () => {
    const navigate = useNavigate();
    const { updateHistory } = useSessionContext();

    return (
      <Box display={isOpenPresentationModal ? "block" : "none"}>
        <Box
          position="fixed"
          zIndex={3}
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.3)"
          onClick={() => setOpenPresentationModal(false)}
        />
        <Flex
          bg="white"
          display="flex"
          alignItems="center"
          borderRadius="12px"
          width="800px"
          maxWidth="800px"
          position="fixed"
          zIndex={3}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          padding="2rem"
          flexDirection="column"
          gap="2rem"
        >
          <Box w="100%">
            <Heading ml="1rem" textAlign="left" fontSize="26">
              Modèle de présentation*
            </Heading>
            <Text ml="1rem" textAlign="left">
              *Non applicable pour votre personnel Masculin
            </Text>
          </Box>

          <Flex direction="row" gap="24px">
            <GenericDressingSelectionCard
              title="Cheveux"
              icon={Icons.HAIR_ICON}
              values={availableHairStyles}
              onClick={setHair}
              selected={dressCode?.presentation?.hair}
              key={3}
            />
            <MakeupItemCard
              onClickNails={setNails}
              onClickLipstick={setLipstick}
              selectedNails={dressCode?.presentation?.nails}
              selectedLipstick={dressCode?.presentation?.lipstick}
              key={4}
            />
          </Flex>
          <Flex direction="row" gap="24px">
            <Button
              colorScheme="blue"
              type="submit"
              disabled={!isPresentationValid()}
              onClick={() => {
                updateHistory(PageURL.OPTIONS);
                navigate(PageURL.OPTIONS);
              }}
            >
              Valider
            </Button>
            <Button
              colorScheme="transparent"
              color={colors.blue.lightMode}
              _hover={{ color: colors.blue.default }}
              type="submit"
              disabled={!isPresentationValid()}
              onClick={() => {
                updateHistory(PageURL.OPTIONS);
                navigate(PageURL.OPTIONS);
              }}
            >
              Ignorer cette étape
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  };

  const { track } = useSegment();

  return (
    <>
      <Tabs
        defaultIndex={16}
        orientation="horizontal"
        variant="unstyled"
        gap="20px !important"
        alignItems="stretch"
        sx={{
          ".chakra-tabs__tablist": {
            display: "flex",
            justifyContent: "flex-start",
            gap: "24px",
            mb: "20px",
            height: "-webkit-fill-available",
            "@media(max-width: 1600px)": {
              height: "auto",
            },
            "button[aria-selected=true] .headingTabItem": {
              backgroundColor: colors.blue.lightMode,
              p: {
                color: colors.white,
              },
            },
          },
          ".chakra-tabs__tab": {
            padding: 0,
          },
          ".chakra-tabs__tab-panels": {
            "& > div": {
              padding: 0,
            },
          },
        }}
      >
        <TabList
          // border={`1px solid ${colors.gray.lightMode}`}
          // borderRadius="32px"
          padding="24px"
          width="fit-content"
        >
          {enableProfessionalOutfit() && (
            <Tab onClick={activeProfessionalOutfit}>
              <DressingTabMenu
                menuTitle="Tenue professionnelle"
                menuIcon={Icons.PROFESSIONAL_OUTFIT_ICON}
                tooltipText="Tenues fournies par l'agence."
              />
            </Tab>
          )}
          {enablePersonalOutfit() && (
            <Tab onClick={activePersonalOutfit}>
              <DressingTabMenu
                menuTitle="Tenue personnelle"
                menuIcon={Icons.PERSONAL_OUTFIT_ICON}
                tooltipText="Tenues appartenant au personnel"
              />
            </Tab>
          )}
          {enableProvidedOutfit() && (
            <Tab onMouseDown={activeProvidedOutfit}>
              <DressingTabMenu
                menuTitle="Je fournis les tenues"
                menuIcon={Icons.JE_FOURNIS_LES_TENUES}
                tooltipText="Vous fournissez les tenues à l'ensemble des équipes"
              />
            </Tab>
          )}
          {enablePrintedOutfit() && (
            <Tab onClick={activePrintedOutfit}>
              <DressingTabMenu
                menuTitle="Tenue imprimée"
                menuIcon={Icons.OPTIONS_PRINTED_CLOTHING}
                tooltipText="Tenues Brandées avec un logo, une marque..."
              />
            </Tab>
          )}
        </TabList>
        <OpenPresentationChoice />
        <TabPanels>
          {enableProfessionalOutfit() && (
            <TabPanel key={1}>
              <Flex
                display="grid"
                gap="15px"
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  "@media(max-width: 1450px), @media(max-height: 850px)": {
                    gridTemplateColumns: "1fr 1fr",
                  },
                }}
              >
                <ProfessionalOutfitMenu
                  setDressCode={setDressCode}
                  dressCode={dressCode}
                />
              </Flex>
            </TabPanel>
          )}
          {enablePersonalOutfit() && (
            <TabPanel key={2}>
              <Flex
                display="grid"
                gap="15px"
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  "@media(max-width: 1450px), @media(max-height: 850px)": {
                    gridTemplateColumns: "1fr 1fr",
                  },
                }}
              >
                <PersonalOutfitMenu
                  dressCode={dressCode}
                  openToggleSection={isOpenDetailedMenu}
                  filter={
                    eventType === EventType.STREET_MARKETING
                      ? "street"
                      : undefined
                  }
                  onActivePersonalCustomMenu={activePersonalCustomMenu}
                  setDressCode={setDressCode}
                />
              </Flex>
            </TabPanel>
          )}
          {enableProvidedOutfit() && (
            <TabPanel key={3}>
              <Flex
                display="grid"
                gap="15px"
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  "@media(max-width: 1450px), @media(max-height: 850px)": {
                    gridTemplateColumns: "1fr 1fr",
                  },
                }}
              >
                <ProvidedOutfitMenu
                  setDressCode={setDressCode}
                  dressCode={dressCode}
                />
              </Flex>
            </TabPanel>
          )}
          {enablePrintedOutfit() && (
            <TabPanel key={4}>
              <Flex>
                {
                  <Flex flexDir="column">
                    <PrintedOutfitMenu
                      dressCode={dressCode}
                      setDressCode={setDressCode}
                      totalStaff={amountOfPersonels}
                      tshirtInfoNeeded={totalEventDays > 2}
                    />
                    <Box w="100%" h="1px" bg="#aaa" my="1rem" />
                    <PrintedOutfitSubMenu
                      dressCode={dressCode}
                      setDressCode={setDressCode}
                    />
                  </Flex>
                }
              </Flex>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <BottomBlock
        onNextPage={() => {
          track("dressing type selected", {
            type: dressCode?.outfitType?.toString() ?? "undefined",
          });
          setOpenPresentationModal(true);
        }}
        disableNextPage={!valid}
      />
    </>
  );
}
