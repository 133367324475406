/// <reference types="@types/segment-analytics" />

import React, { createContext, useContext } from "react";
import Segment from "react-segment-analytics";
import type { Traits } from "react-segment-analytics";
import KEYS from "../keys";

type EventProperties = string | number | boolean | null;
interface Event {
  // we could add more
  name:
    | "Go to website" // Click on logo
    | "Screen too small" // width < 1100px OR height < 640px
    | "Waaa le zoom" // zoom is huge
    //
    | "Select Personnel d'Accueil" // Welcome screen select "Personnel d’Accueil" option
    | "Select Personnel d'Animation" // Welcome screen select "Personnel d’Animation" option
    | "Select Distribution & Echantillonage" // Welcome screen select "Distribution & Echantillonage" option
    //
    | "Previous page" // Click on revenir à l'étape précédente
    | "Reset order" // with the button in total card
    | "Continue on refresh" // continue after f5
    | "Reset on refresh" // reset after f5
    | "Contact" // send a contact form
    | "Close help bubble" // Click on close help bubble
    //
    | "Need help" // Click on besoin d'aide
    | "Don't need help" // Click on pas besoin d'aide
    //
    | "Emargement selection" // Onboarding step emargement
    | "Event size" // nb of participants OR m²
    | "Arriving schema" //onboarding "Comment vos invités se rendront-ils sur place ?"
    | "Locker room" // Onboarding selection vestiaire
    //
    | "Flyers step 1: own flyers ?" // Street Mrktg step 1
    | "Flyers selection" // Street Mrktg step 2
    | "Flyers option selected" // Street Mrktg step 3
    //
    | "Address found by Google" // Address filled (found by Google)
    | "City found by Google" // Address filled (found by Google)
    | "Address filled" // Address set by the user
    //
    | "Staff selection tab clicked" // Click on tab in personal selection
    | "Decrement personal" // Decrement personal
    | "Increment personal" // Increment personal
    | "Copy schedule" // Click on copy schedule
    | "Paste schedule" // Click on paste schedule
    //
    | "dressing type selected" // Dressing
    //
    | "Decrement option" // Decrement option
    | "Increment option" // Increment option
    //
    | "Give a brief" // Write smt in brief section
    //
    | "Download quote" // Click on download quote";
    | "Pay order" // Click on finish order
    | "Discount code added" // Click on add discount code
    //
    | "Modify order after quote"; // Click on modify order after quote
  //
  properties: {
    [key: string]: EventProperties;
  };
}

interface ContextProps {
  load: () => void;
  // IDK
  identify: (userId: string, traits: Traits) => void;
  // identify users
  page: (name: string) => void;
  // follow users movements between pages
  track: (eventName: Event["name"], properties?: Event["properties"]) => void;
  // track custom events
}

const SegmentContext = createContext<ContextProps>({
  load: () => {
    console.warn("Segment load method not initialized");
  },
  identify: () => {
    console.warn("Segment identify method not initialized");
  },
  page: () => {
    console.warn("Segment page method not initialized");
  },
  track: () => {
    console.warn("Segment track method not initialized");
  },
});

interface Props {
  children: React.ReactNode;
}

const SegmentProviderInner: React.FC<Props> = ({ children }) => {
  const load = () => {
    if (window.analytics) window.analytics.load(KEYS.SEGMENT_WRITE_KEY);
  };
  const identify = (userId: string, traits: any) => {
    if (window.analytics) window.analytics.identify(userId, traits);
  };

  const page = (name: string) => {
    if (window.analytics) window.analytics.page(name);
  };
  const track = (name: string, properties: any) => {
    if (window.analytics) window.analytics.track(name, properties);
  };

  return (
    <SegmentContext.Provider
      value={{
        load,
        identify,
        page,
        track,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

export const SegmentProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  return (
    <Segment writeKey={KEYS.SEGMENT_WRITE_KEY}>
      <SegmentProviderInner>{children}</SegmentProviderInner>
    </Segment>
  );
};

export const useSegment = (): ContextProps => useContext(SegmentContext);
