import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OptionOutput } from "../../apollo/calendar/mutations";
import { useCustomerContext } from "../../context/CustomerContext";
import { useOnboardingContext } from "../../context/OnboardingContext";
import {
  BackendOptionType,
  OPTIONS_LIST,
  OptionInformation,
  OptionType,
  useOptionContext,
} from "../../context/OptionsContext";
import { useSegment } from "../../context/SegmentContext";
import { useSessionContext } from "../../context/SessionContext";
import {
  EventDateInfo,
  PERSONNEL_TYPE_LABELS,
  PersonnelSelectionInfo,
  PersonnelType,
  useUserContext,
} from "../../context/UserContext";
import useOptionsData from "../../hooks/getOptionsData";
import { useResetOrder } from "../../hooks/useResetOrder";
import { getHourValue } from "../../modules/Calendar/CalendarEventSchedule";
import {
  EVENT_CATEGORY_TEXTS,
  EVENT_SUBCATEGORY_TEXTS,
  EventCategory,
  EventSubCategory,
} from "../../modules/Onboarding/EventTypes";
import { nbParticipantsToDisplayStr } from "../../modules/Onboarding/onboardingUtils";
import { Abc } from "../../modules/Onboarding/type";
import { PageURL } from "../../types/pages";
import { colors } from "../../ui/theme";
import {
  DetailedPersonnel,
  createDetailedPersonnelArray,
} from "../../utils/parseUserData";
import {
  calcDiffHours,
  formatingNumber,
  getDayList,
  getDayListFromPerdiod,
  showEventDayHoursToUser,
} from "../../utils/utils";
import CustomTooltip, { setMealTooltipContent } from "../CustomTooltip";
import { FLYER_DISTRIBUTION_CONFIG } from "../FlyersBlock/flyersDistributionConfig";
import { ReinitialisationIcon } from "../Icons/ReinitialisationIcon";
import CustomerInfoPopup from "../Popus/CustomerInfoPopup";
import { ReinistalisationsProps } from "./types";

export const dressingTypeTranslation: { [key: string]: string } = {
  "Tenue personnelle": "Tenues personnelles",
  "Tenue professionnelle": "Tenues professionnelles",
  "Tenue imprimée": "Tenues imprimées",
  "Je fournis les tenues": "Tenues fournies",
};

const LUNCH_TIME = "13:00";
const DINNER_TIME = "20:00";
interface TotalPriceComponentProps {
  priceHt?: number;
  priceTtc?: number;
  priceTva?: number;
  color?: string;
  showAcompte?: boolean;
  discountErrorMessage?: string;
  priceHtDiscounted?: number;
  discountPercent?: number;
}

const TotalCard: FC<TotalCardProps> = ({
  priceHt,
  priceTtc,
  priceTva,
  showAcompte = false,
  discountErrorMessage,
  priceHtDiscounted = 0,
  discountPercent = 0,
}) => {
  const { userInfos, setUserInfos } = useUserContext();
  const { optionInfo } = useOptionContext();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [savedPrice, setSavedPrice] = useState<number>();
  const [discountCode, setDiscountCode] = useState<string | undefined>();
  const { track } = useSegment();
  useEffect(() => {
    if (priceHt === undefined) {
      setIsLoading(false);
    } else {
      if (savedPrice !== priceHt) {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        setSavedPrice(priceHt);
      }
    }
  }, [priceHt, savedPrice]);

  useEffect(() => {
    if (userInfos?.discountCode) {
      setDiscountCode(userInfos?.discountCode);
    }
  }, [userInfos.discountCode]);

  useEffect(() => {
    // Scroll to the bottom when new elements are added
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [priceHt]);

  const timer: any = (): void => {
    setShowLoader(false);
    navigate(PageURL.HOME);
  };
  const { completeResetOrder } = useResetOrder();

  const deleteUserInfosData = () => {
    setShowLoader(true);
    localStorage.removeItem("userInfos");
    localStorage.removeItem("customerInfos");
    completeResetOrder();
    setTimeout(timer, 500);
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  });

  const { customerInfo, setDataNeeded, checkContextInfo } =
    useCustomerContext();
  const { sessionInfo } = useSessionContext();
  const onSubmit = () => {
    setDataNeeded(false);
  };

  useEffect(() => {
    checkContextInfo();
  });

  const selectDePersonnel = userInfos?.selectDePersonnelInfo?.persons;

  const amountOfPersonels =
    (selectDePersonnel &&
      Object.keys(selectDePersonnel).reduce((acc, personKey) => {
        return acc + selectDePersonnel[personKey as PersonnelType]!.count;
      }, 0)) ||
    0;

  const amountOfOptions =
    (optionInfo &&
      Object?.keys(optionInfo.selectedOptions)?.filter(
        (optionKey) => optionInfo?.selectedOptions[optionKey as OptionType] > 0
      )?.length) ||
    0;

  const totalEventDays = getDayList(userInfos.calendarInfo!)?.length ?? 1;

  const printDressingOption = (): string => {
    if (userInfos?.outfitInfos?.professionalOutfit) {
      return ": " + userInfos?.outfitInfos?.professionalOutfit;
    } else if (userInfos?.outfitInfos?.personalOutfit) {
      if (userInfos.outfitInfos.personalOutfit.generic) {
        return ": " + userInfos.outfitInfos.personalOutfit.generic;
      } else if (userInfos.outfitInfos.personalOutfit.custom) {
        return ": Personnalisée";
      }
    }
    return "";
  };
  const { eventName, eventLocations } = useOnboardingContext();

  return (
    <Box width="300px" minWidth="300px" height="100%" zIndex={2}>
      <Flex
        bg="#FFF"
        maxHeight={"calc(100vh - 275px)"}
        direction="column"
        position="fixed"
        alignItems="center"
        border={`1px solid ${colors.gray.lightMode}`}
        borderRadius={{ md: "20px", "2xl": "24px" }}
        padding="1.5rem"
        width="300px"
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          flexDir="column"
          textAlign="left"
        >
          {(eventName ||
            eventLocations ||
            userInfos.calendarInfo?.days ||
            userInfos.calendarInfo?.period) && (
            <Text variant="md" fontWeight="900" mb="0.5rem">
              VOTRE EVENEMENT
            </Text>
          )}
          {eventName && (
            <>
              <CustomTooltip padding="0.5rem 1rem" content={[eventName]}>
                <Flex flexDirection={"row"}>
                  <Text mr="4px">
                    <b>Nom : </b>
                  </Text>
                  <Text
                    textDecoration="underline"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {eventName}
                  </Text>
                </Flex>
              </CustomTooltip>
            </>
          )}

          {eventLocations && (
            <>
              <CustomTooltip
                padding="0.5rem 1rem"
                content={eventLocations.map((location) => location.address)}
              >
                <Flex flexDirection={"row"}>
                  <Text mr="4px" minW="60px">
                    <b>Adresse : </b>
                  </Text>
                  {eventLocations.length < 2 ? (
                    eventLocations.map((location, index) => {
                      return (
                        <Text
                          key={index}
                          textDecoration="underline"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {location.address}
                        </Text>
                      );
                    })
                  ) : (
                    <Text
                      textDecoration="underline"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {
                        eventLocations.filter(
                          (eventLocation) => eventLocation.address !== ""
                        ).length
                      }{" "}
                      adresses
                    </Text>
                  )}
                </Flex>
              </CustomTooltip>
            </>
          )}
          {userInfos.calendarInfo?.days &&
            userInfos.calendarInfo.days.length <= 2 && (
              <>
                <Text>
                  <b>Dates : </b>
                </Text>
                {userInfos.calendarInfo.days
                  .sort((a, b) => moment(a).unix() - moment(b).unix())
                  .map((day) => {
                    const toShow = showEventDayHoursToUser(
                      userInfos?.calendarInfo?.eventSchedule?.find(
                        (schedule) => schedule.day === day
                      )
                    );
                    return (
                      <Text key={day.toString()}>
                        -{" "}
                        {moment(day).toDate().toLocaleDateString("fr-FR", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                        {toShow}
                      </Text>
                    );
                  })}
              </>
            )}
          {userInfos.calendarInfo?.days &&
            userInfos.calendarInfo.days.length > 2 && (
              <CustomTooltip
                content={userInfos?.calendarInfo?.days
                  ?.sort((a, b) => moment(a).unix() - moment(b).unix())
                  .map((day) => {
                    const toShow = showEventDayHoursToUser(
                      userInfos?.calendarInfo?.eventSchedule?.find(
                        (schedule) => schedule.day === day
                      )
                    );
                    return (
                      moment(day)
                        .toDate()
                        .toLocaleDateString("fr-FR", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                        .toString() + toShow
                    );
                  })}
              >
                <Flex flexDirection="row">
                  <Text mr="4px">
                    <b>{"Durée :"}</b>
                  </Text>
                  <Text textDecoration="underline">
                    {userInfos.calendarInfo.days.length} Jours
                  </Text>
                </Flex>
              </CustomTooltip>
            )}
          {userInfos.calendarInfo?.period && (
            <CustomTooltip
              content={getDayListFromPerdiod(userInfos?.calendarInfo?.period)
                ?.sort((a, b) => moment(a).unix() - moment(b).unix())
                .map((day) => {
                  const toShow = showEventDayHoursToUser(
                    userInfos?.calendarInfo?.eventSchedule?.find(
                      (schedule) => schedule.day === day
                    )
                  );
                  return (
                    moment(day)
                      .toDate()
                      .toLocaleDateString("fr-FR", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })
                      .toString() + toShow
                  );
                })}
            >
              <Text textDecoration="underline">
                {"Du "}
                {moment(userInfos.calendarInfo?.period.startDate)
                  .toDate()
                  .toLocaleDateString("fr-FR", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                {" au "}
                {moment(userInfos.calendarInfo?.period.endDate)
                  .toDate()
                  .toLocaleDateString("fr-FR", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
              </Text>
            </CustomTooltip>
          )}
        </Flex>
        {(eventName ||
          eventLocations ||
          userInfos.calendarInfo?.days ||
          userInfos.calendarInfo?.period) && (
          <Flex
            borderTop="1px solid #E2E8F0"
            width="100%"
            mt="16px"
            justifyContent="space-between"
            pt="16px"
            flexDir="column"
            textAlign="left"
          />
        )}
        <Box
          w="100%"
          pr="0.5rem"
          h="fit-content"
          overflow="auto"
          py="0.5rem"
          ref={scrollableRef}
        >
          <Flex
            direction="column"
            gap="2px"
            alignItems="flex-start"
            width="100%"
          >
            <Flex
              gap="6px"
              alignItems="flex-start"
              direction="column"
              width="100%"
              maxHeight={{ md: "330px", "2xl": "400px" }}
            >
              <OnboardingInfos />
              {amountOfPersonels > 0 && (
                <>
                  <Text variant="md" fontWeight="900">
                    Votre équipe:
                  </Text>
                  <PersonnelRecapfunction
                    selectPersonnelInfo={userInfos.selectDePersonnelInfo}
                    calendarInfo={userInfos.calendarInfo!}
                  />
                </>
              )}
              {userInfos?.outfitInfos?.outfitType && (
                <>
                  <Text variant="md" fontWeight="900">
                    Dressing:
                  </Text>
                  <Text variant="sm" textAlign="left">
                    {`${
                      dressingTypeTranslation[
                        userInfos?.outfitInfos?.outfitType as string
                      ]
                    }`}
                    {printDressingOption()}
                  </Text>
                </>
              )}
              {amountOfOptions > 0 && showAcompte && (
                <OptionRecapTooltip optionInfo={optionInfo}>
                  <Text variant="md" fontWeight="900" decoration="underline">
                    Options:
                  </Text>
                </OptionRecapTooltip>
              )}
              {amountOfOptions > 0 && !showAcompte && (
                <>
                  <Text variant="md" fontWeight="900">
                    Options:
                  </Text>
                  <OptionRecapFunction
                    optionInfo={optionInfo}
                    nbDays={totalEventDays}
                    nbPersons={amountOfPersonels}
                  />
                </>
              )}
              <ReintitialiserButton
                showLoader={showLoader}
                deleteUserInfosData={deleteUserInfosData}
              />
            </Flex>
          </Flex>
        </Box>

        {priceTtc ? (
          <VStack
            borderTop="1px solid #E2E8F0"
            width="100%"
            mt="0.75rem"
            pt="16px"
          >
            {!!discountPercent && userInfos.discountCode && (
              <HStack width={"100%"} alignContent={"center"}>
                <Text variant="sm">{"Réduction Partenaire:"}</Text>
                <Text variant="xs">{userInfos.discountCode}</Text>
                <Spacer />
                <Text variant="xs">{discountPercent * 100 + " %"}</Text>
              </HStack>
            )}
            <Flex
              width="100%"
              justifyContent="space-between"
              flexDir={showAcompte ? "column" : "row"}
              textAlign="left"
            >
              {showAcompte ? (
                <>
                  {priceHt && priceTva && (
                    <>{detailledPriceComponent(priceHt, priceTva)}</>
                  )}
                  <Flex
                    direction="row"
                    mt="0.75rem"
                    justifyContent="space-between"
                    mr="1rem"
                  >
                    <Text variant="sm" fontWeight="700">
                      Montant total TTC
                    </Text>
                    <TotalPriceComponent
                      isLoading={isLoading}
                      priceTtc={priceTtc}
                      showAcompte
                    />
                  </Flex>
                  <Flex
                    direction="row"
                    mt="0.75rem"
                    justifyContent="space-between"
                    mr="1rem"
                  >
                    <Text variant="sm" fontWeight="700" maxW="130px">
                      {"50% d’Acompte (À payer aujourd’hui)"}
                    </Text>
                    <TotalPriceComponent
                      isLoading={isLoading}
                      priceTtc={(priceTtc ?? 0) / 2}
                      showAcompte
                    />
                  </Flex>

                  <Flex
                    direction="row"
                    mt="0.75rem"
                    justifyContent="space-between"
                    mr="1rem"
                  >
                    <CustomTooltip
                      padding="0.75rem 1.5rem"
                      fontSize="14px"
                      placement="top"
                      content={[
                        "+/- 5% ajustés en fonction de modifications durant l'événement pour vous permettre d'être flexible.",
                      ]}
                    >
                      <Text
                        variant="sm"
                        fontWeight="700"
                        decoration="underline"
                        color={colors.blue.lightMode}
                      >
                        Restant après l’événement
                      </Text>
                    </CustomTooltip>
                    <TotalPriceComponent
                      isLoading={isLoading}
                      color={colors.blue.lightMode}
                      priceTtc={(priceTtc ?? 0) / 2}
                      showAcompte
                    />
                  </Flex>
                </>
              ) : (
                <>
                  {customerInfo?.dataNeeded === true ? (
                    !sessionInfo?.reset && (
                      <CustomerInfoPopup isOpen={true} onSubmit={onSubmit} />
                    )
                  ) : (
                    <VStack width={"100%"}>
                      <HStack width={"100%"} alignItems={"flex-start"}>
                        <Text variant="md" fontWeight="900">
                          PRIX HT
                        </Text>
                        <Spacer />
                        <TotalPriceComponent
                          isLoading={isLoading}
                          priceHt={priceHt}
                          priceHtDiscounted={priceHtDiscounted}
                          discountPercent={discountPercent}
                        />
                      </HStack>
                      <VStack width={"100%"} marginTop={"16px"}>
                        <InputGroup size="md">
                          <Input
                            placeholder="Code partenaire"
                            maxW="400px"
                            value={discountCode}
                            onChange={(e) => {
                              setDiscountCode(e.target.value.toUpperCase());
                            }}
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={() => {
                                setUserInfos({
                                  ...userInfos,
                                  discountCode: discountCode,
                                });
                                if (discountCode) {
                                  track("Discount code added", {
                                    discountCode: discountCode,
                                    discountPercent: discountPercent,
                                    priceHtDiscounted: priceHtDiscounted,
                                  });
                                }
                                setDiscountCode("");
                              }}
                            >
                              {"Ok"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <Text variant={"md"}>{discountErrorMessage}</Text>
                      </VStack>
                    </VStack>
                  )}
                </>
              )}
            </Flex>
          </VStack>
        ) : (
          <Flex w="100%" justify="space-between">
            <Text variant="md" fontWeight="900" w="40%">
              PRIX HT
            </Text>
            <Flex
              alignItems={showAcompte ? "flex-start" : "flex-end"}
              flexDir={showAcompte ? "row" : "column"}
            >
              <Flex direction="column" textAlign="right">
                <Text
                  color={colors.blue.lightMode}
                  variant={showAcompte ? "sm" : "md"}
                  fontWeight="700"
                >
                  _ _ . _ _ €
                </Text>
              </Flex>
              {showAcompte ?? (
                <Text variant="xs" width={"80%"}>
                  En attente du personnel et des horaires de l'évenement
                </Text>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

const ReintitialiserButton: FC<ReinistalisationsProps> = ({
  showLoader,
  deleteUserInfosData,
}): ReactElement => {
  const { track } = useSegment();
  const handleClick = () => {
    track("Reset order");
    deleteUserInfosData();
  };
  return (
    <Flex
      onClick={handleClick}
      cursor="pointer"
      gap="5px"
      alignItems="center"
      textAlign="left"
    >
      {showLoader ? (
        <Spinner />
      ) : (
        <>
          <ReinitialisationIcon
            width="10px"
            fill={colors.red.default}
            height="10px"
          />
          <Text variant="sm" fontWeight="700" color={colors.red.default}>
            Réinitialiser la commande
          </Text>
        </>
      )}
    </Flex>
  );
};

function OnboardingInfos() {
  const {
    eventCategory,
    eventSubcategory,
    eventCustomSubcategory,
    nbParticipants,
    salonSize,
    transport,
    flyerConfig,
    sellingPointConfig,
  } = useOnboardingContext();

  let data = useOptionsData("flyers");

  function getFlyersPrice(nb: number) {
    if (!data || !data.data || !data.data.options) return "indisponible";
    const option = data.data.options.find(
      (option: OptionOutput) => option.count === nb
    );
    if (!option?.priceTotal) return "indisponible";
    return option.priceTotal.toFixed(2) + " €";
  }

  function getFlyersPriceDetails(nb: number) {
    if (!data || !data.data || !data.data.options) return "prix indisponible";
    const option = data.data.options.find(
      (option: OptionOutput) => option.count === nb
    );
    if (!option?.pricePackage || !option?.priceDelivery)
      return "prix indisponible";
    return (
      "Prix des flyers: " +
      option.pricePackage +
      " € + Frais de livraison: " +
      option.priceDelivery +
      "€"
    );
  }

  const [distributionTotalHours, setDistributionTotalHours] = useState<
    string[] | undefined
  >(undefined);
  useEffect(() => {
    if (!flyerConfig?.optionSelected) return;
    let conf = undefined;
    const config = FLYER_DISTRIBUTION_CONFIG.find(
      (config) => config.flyers === flyerConfig?.nbFlyers
    );
    if (!config) return;
    if (flyerConfig?.optionSelected === Abc.A) conf = config?.configs[0];
    if (flyerConfig?.optionSelected === Abc.B) conf = config?.configs[1];
    if (flyerConfig?.optionSelected === Abc.C) conf = config?.configs[2];
    if (conf) {
      const total =
        conf.teams * conf.personsPerTeam * conf.days * conf.hoursPerDay;
      setDistributionTotalHours([
        total.toString() + "h heures de distribution",
      ]);
    }
  }, [flyerConfig]);

  return (
    <>
      {eventCategory && (
        <>
          <Text variant="md" fontWeight="700">
            Type d'événement
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {EVENT_CATEGORY_TEXTS[eventCategory as EventCategory].totalCard}
            {eventSubcategory
              ? ` - ${
                  EVENT_SUBCATEGORY_TEXTS[eventSubcategory as EventSubCategory]
                    .totalCard
                }`
              : ""}
            {eventCustomSubcategory ? ` - ${eventCustomSubcategory}` : ""}
          </Text>
        </>
      )}
      {nbParticipants && (
        <>
          <Text variant="md" fontWeight="700">
            Nombre d'invités prévus par jour
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {nbParticipantsToDisplayStr(nbParticipants)}
          </Text>
        </>
      )}
      {salonSize && (
        <>
          <Text variant="md" fontWeight="700">
            Taille du stand
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {salonSize}
          </Text>
        </>
      )}
      {transport !== undefined && transport[0] && (
        <>
          <Text variant="md" fontWeight="700">
            Arrivée des invités
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {transport![0]}
          </Text>
          {transport![1] && (
            <Text variant="sm" fontWeight="500" textAlign="left">
              {transport![1]}
            </Text>
          )}
        </>
      )}
      {(flyerConfig?.ownFlyers || flyerConfig?.nbFlyers) && (
        <>
          <Text variant="md" fontWeight="700">
            Vous avez vos propres flyers
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {flyerConfig.ownFlyers ? "Oui" : "Non"}
          </Text>
        </>
      )}
      {flyerConfig?.nbFlyers && flyerConfig?.ownFlyers !== true && (
        <>
          <Text variant="md" fontWeight="700">
            Nombre de flyers à distribuer
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {flyerConfig.nbFlyers} flyers
          </Text>
          <CustomTooltip
            content={[getFlyersPriceDetails(flyerConfig.nbFlyers)]}
          >
            <Flex flexDirection={"row"}>
              <Text mr="4px" fontSize="12px">
                Prix des flyers :
              </Text>
              <Text
                align="left"
                fontSize="12px"
                fontWeight="bold"
                textDecoration="underline"
              >
                {getFlyersPrice(flyerConfig.nbFlyers)}
              </Text>
            </Flex>
          </CustomTooltip>
        </>
      )}
      {flyerConfig?.optionSelected && (
        <>
          <Text variant="md" fontWeight="700">
            Stratégie de distribution
          </Text>
          <CustomTooltip content={distributionTotalHours}>
            <Text
              variant="sm"
              fontWeight="500"
              textAlign="left"
              decoration="underline"
            >
              {flyerConfig.optionSelected === Abc.A
                ? "Option A: Récurrence & mémorisation"
                : flyerConfig.optionSelected === Abc.B
                ? "Option B: Intermédiaire"
                : "Option C: Impacte & notoriété"}
            </Text>
          </CustomTooltip>
        </>
      )}
      {sellingPointConfig.sellingPoints && (
        <>
          <Text variant="md" fontWeight="700">
            Points de vente
          </Text>
          <Text variant="sm" fontWeight="500" textAlign="left">
            {sellingPointConfig.sellingPoints} point(s) de vente
            {sellingPointConfig.sameLocation === true
              ? " dans la même ville"
              : sellingPointConfig.sameLocation === false
              ? " dans plusieurs villes"
              : ""}
          </Text>
        </>
      )}
    </>
  );
}

type RegroupPersonOutputObject = {
  type: string;
  hours: number;
  person: number;
  date: string;
};

type RegroupDaysOutputObject = {
  type: string;
  hours: number;
  person: number;
  date: string;
  day: number;
};

function regroupPersons(
  schedule: DetailedPersonnel[]
): RegroupPersonOutputObject[] {
  const result: RegroupPersonOutputObject[] = [];

  schedule.forEach((obj) => {
    const existingObj = result.find(
      (outputObj) =>
        outputObj.type === obj.type &&
        outputObj.hours === obj.workedHours &&
        outputObj.date === obj.day
    );

    if (existingObj) {
      existingObj.person++;
    } else {
      result.push({
        person: 1,
        type: obj.type,
        hours: obj.workedHours,
        date: obj.day,
      });
    }
  });

  return result;
}

function regroupDays(
  schedule: RegroupPersonOutputObject[]
): RegroupDaysOutputObject[] {
  const result: RegroupDaysOutputObject[] = [];
  schedule.forEach((obj) => {
    const existingObj = result.find(
      (outputObj) =>
        outputObj.type === obj.type &&
        outputObj.person === obj.person &&
        outputObj.hours === obj.hours &&
        outputObj.date !== obj.date
    );

    if (existingObj) {
      existingObj.day++;
    } else {
      result.push({
        day: 1,
        date: obj.date,
        person: obj.person,
        type: obj.type,
        hours: obj.hours,
      });
    }
  });
  return result;
}
export function OptionRecapTooltip({
  optionInfo,
  children,
}: {
  optionInfo: OptionInformation;
  children: ReactElement;
}): ReactElement {
  if (!optionInfo) return <></>;

  let content: { name: string; price: any }[] = [];

  // eslint-disable-next-line array-callback-return
  Object.keys(optionInfo?.selectedOptions).map((option) => {
    if (optionInfo?.selectedOptions[option as OptionType] !== 0) {
      const key = Object.keys(OptionType).find(
        (key) => OptionType[key as keyof typeof OptionType] === option
      );
      const count = optionInfo?.selectedOptions[option as OptionType];

      const queryOptionName =
        BackendOptionType[key as keyof typeof BackendOptionType];
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const query = useOptionsData(queryOptionName);

      const backendData = query?.data?.options;

      const optionName =
        OPTIONS_LIST[OptionType[key as keyof typeof OptionType]].name;

      let optionPrice: OptionOutput | undefined = undefined;
      if (count !== 0) {
        optionPrice = backendData?.find(
          (option: OptionOutput) => option?.count === count
        );
      } else option = backendData[0];
      if (backendData?.length === 1) {
        content.push({
          name: optionName,
          price: backendData[0].priceUnit * count,
        });
      } else if (optionPrice)
        content.push({ name: optionName, price: optionPrice.priceTotal });
      else content.push({ name: optionName, price: "?" });
    }
  });
  return (
    <Tooltip
      width="auto"
      border={`1px solid ${colors.secondaryFontColor.lightMode}`}
      borderRadius={"16px"}
      backgroundColor={"#fff"}
      label={
        content
          ? content.map((item, index) => (
              <Flex
                p="0.25rem 0.5rem"
                justifyContent="space-between"
                w="300px"
                key={index}
              >
                <Text fontSize="16px" color="black">
                  {item.name} :
                </Text>
                <Text fontSize="16px" color="black">
                  {item.price} €
                </Text>
              </Flex>
            ))
          : ""
      }
    >
      {children}
    </Tooltip>
  );
}

export function OptionRecapFunction({
  optionInfo,
  fontsize,
  nbDays,
  nbPersons,
}: {
  optionInfo: OptionInformation;
  fontsize?: string;
  nbDays: number;
  nbPersons: number;
}): ReactElement {
  if (!optionInfo) return <></>;
  return (
    <>
      {Object.keys(optionInfo?.selectedOptions).map((option, key) => {
        if (optionInfo?.selectedOptions[option as OptionType] === 0)
          return null;
        return (
          <OptionInfoValues
            key={key}
            optionName={option}
            optionAmount={optionInfo?.selectedOptions[option as OptionType]}
            fontsize={fontsize}
            nbDays={nbDays}
            nbPersons={nbPersons}
          />
        );
      })}
    </>
  );
}
export function PersonnelRecapfunction({
  selectPersonnelInfo,
  calendarInfo,
  fontsize,
}: {
  selectPersonnelInfo: PersonnelSelectionInfo;
  calendarInfo: EventDateInfo;
  fontsize?: string;
}): ReactElement {
  const staff = useMemo(
    () => createDetailedPersonnelArray(selectPersonnelInfo, calendarInfo),
    [selectPersonnelInfo, calendarInfo]
  );
  const lunch = useMemo(() => {
    let count = 0;
    staff.forEach((item) => {
      if (item.startHour && item.endHour)
        if (
          calcDiffHours(item.endHour, item.startHour) >= 6.5 &&
          getHourValue(item.startHour) + 1.5 < getHourValue(LUNCH_TIME) &&
          getHourValue(LUNCH_TIME) < getHourValue(item.endHour) - 1.5
        )
          count += 1;
    });
    return count;
  }, [staff]);
  const dinner = useMemo(() => {
    let count = 0;
    staff.forEach((item) => {
      if (item.startHour && item.endHour)
        if (
          calcDiffHours(item.endHour, item.startHour) >= 6.5 &&
          getHourValue(item.startHour) + 1.5 < getHourValue(DINNER_TIME) &&
          getHourValue(DINNER_TIME) < getHourValue(item.endHour) - 1.5
        )
          count += 1;
    });
    return count;
  }, [staff]);

  let previousType = "";

  if (!selectPersonnelInfo || !calendarInfo) return <></>;
  const rawData = createDetailedPersonnelArray(
    selectPersonnelInfo,
    calendarInfo
  );
  const personRegroupedData = regroupPersons(rawData);
  const dayRegroupedData = regroupDays(personRegroupedData);

  function showTypeName(type: string) {
    if (type !== previousType) {
      previousType = type;
      return true;
    }
    return false;
  }
  return (
    <Flex flexDir="column">
      {dayRegroupedData.map((item, index) => (
        <Box key={index}>
          {showTypeName(item.type) && (
            <Text
              fontWeight="500"
              textAlign="left"
              fontSize={fontsize ?? "12px"}
            >
              <b>{PERSONNEL_TYPE_LABELS[item.type as PersonnelType]}</b>
            </Text>
          )}
          {item.hours !== 0 ? (
            <Text
              key={index}
              fontWeight="500"
              ml="0.25rem"
              align="left"
              fontSize={fontsize ?? "12px"}
            >
              - {item.person}p x {item.day}j x {item.hours.toFixed(2)}h
            </Text>
          ) : (
            <Text
              key={index}
              fontWeight="500"
              ml="0.25rem"
              align="left"
              fontSize={fontsize ?? "12px"}
            >
              - {item.person}p x {item.day}j
            </Text>
          )}
        </Box>
      ))}
      {lunch + dinner > 0 && (
        <>
          <CustomTooltip content={setMealTooltipContent(lunch, dinner)}>
            <Text
              mt="0.5rem"
              fontWeight="500"
              textAlign="left"
              fontSize={fontsize ?? "12px"}
              decoration="underline"
            >
              <b>{lunch + dinner} Paniers repas</b>
            </Text>
          </CustomTooltip>
        </>
      )}
    </Flex>
  );
}

interface OptionInfoValuesProps {
  optionName: string;
  optionAmount: number;
  fontsize?: string;
  nbDays: number;
  nbPersons: number;
}

const OptionInfoValues: FC<OptionInfoValuesProps> = ({
  optionName,
  optionAmount,
  fontsize,
  nbDays,
  nbPersons,
}): ReactElement => {
  const key = Object.keys(OptionType).find(
    (key) => OptionType[key as keyof typeof OptionType] === optionName
  );
  const queryOptionName =
    BackendOptionType[key as keyof typeof BackendOptionType];
  const query = useOptionsData(queryOptionName);
  const backendData = query?.data?.options;
  const backendLoading = query?.data?.loading;
  const [backendPrice, setBackendPrice] = useState<number | undefined>(
    undefined
  );
  const [tooltipContent, setTooltipContent] = useState<string>("");

  function capitalizeFirstLetter(str: string) {
    return str.replace(/[a-zA-Z]/, (match) => match.toUpperCase());
  }

  useEffect(() => {
    if (!backendData || backendLoading) {
      setBackendPrice(undefined);
      return;
    }
    let multiplier = 1;

    if (backendData.length === 1) {
      if (backendData[0].perDay) multiplier *= nbDays;
      if (backendData[0].perPerson) multiplier *= nbPersons;
      if (backendData[0].perDay && backendData[0].perPerson)
        setTooltipContent(backendPrice + " € (quantité * staffs * jours)");
      else if (backendData[0].perDay)
        setTooltipContent(backendPrice + " € (quantité * jours)");
      else if (backendData[0].perPerson)
        setTooltipContent(backendPrice + " € (quantité * staffs)");
      else {
        setTooltipContent(
          backendData[0].priceUnit * optionAmount * multiplier + " €"
        );
      }
      setBackendPrice(backendData[0].priceUnit * optionAmount * multiplier);
    }

    if (backendData.length > 1) {
      const option: OptionOutput = backendData.find(
        (option: OptionOutput) => option.count === optionAmount
      );
      if (option?.perDay) multiplier *= nbDays;
      if (option?.perPerson) multiplier *= nbPersons;

      if (option.priceTotal && multiplier === 1) {
        setBackendPrice(option.priceTotal);
        if (option?.perDay && option?.perPerson)
          setTooltipContent(
            backendPrice +
              " € (quantité * staffs * jours)(frais de port inclus)"
          );
        else if (option?.perDay)
          setTooltipContent(
            backendPrice + " € (quantité * jours)(frais de port inclus)"
          );
        else if (option?.perPerson)
          setTooltipContent(
            backendPrice + " € (quantité * staffs)(frais de port inclus)"
          );
      } else {
        if (option?.perDay && option?.perPerson)
          setTooltipContent(backendPrice + " € (quantité * staffs * jours)");
        else if (option?.perDay)
          setTooltipContent(backendPrice + " € (quantité * jours)");
        else if (option?.perPerson)
          setTooltipContent(backendPrice + " € (quantité * staffs)");
        setBackendPrice(backendData[0].priceUnit * optionAmount * multiplier);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionAmount, query]);
  return (
    <CustomTooltip
      padding="0.75rem 1.5rem"
      content={tooltipContent === "" ? [backendPrice + " €"] : [tooltipContent]}
    >
      <Flex>
        <Text
          display="block"
          width="auto"
          fontSize={fontsize ?? "12px"}
          fontWeight="500"
          textTransform="capitalize"
          decoration={backendPrice ? "underline" : ""}
        >
          {optionAmount}
          <br />
        </Text>
        <Text
          decoration={backendPrice ? "underline" : ""}
          fontSize={fontsize ?? "12px"}
          fontWeight="500"
          p="0 2px"
        >
          x
        </Text>
        <Text
          decoration={backendPrice ? "underline" : ""}
          fontSize={fontsize ?? "12px"}
          fontWeight="500"
        >
          {key
            ? capitalizeFirstLetter(
                OPTIONS_LIST[OptionType[key as keyof typeof OptionType]].name
              )
            : capitalizeFirstLetter(optionName)}
          {optionName === "Portant"
            ? " (comprend 50 cintres par portant)".toLowerCase()
            : null}
          <br />
        </Text>
      </Flex>
    </CustomTooltip>
  );
};

interface TotalCardProps extends TotalPriceComponentProps {
  showAcompte?: boolean;
}

const detailledPriceComponent = (ht: number, tva: number) => {
  return (
    <Flex
      borderBottom="1px solid #E2E8F0"
      width="100%"
      direction="column"
      mb="0.25rem"
    >
      <Flex justify="space-between" mb="0.25rem">
        <Text variant="sm" fontWeight="500">
          Total HT
        </Text>
        <Text variant="sm" fontWeight="500" mr="1rem">
          {ht.toFixed(2).toString()} €
        </Text>
      </Flex>
      <Flex justify="space-between" mb="0.25rem">
        <Text variant="sm" fontWeight="500">
          TVA
        </Text>
        <Text variant="sm" fontWeight="500" mr="1rem">
          {tva.toFixed(2).toString()} €
        </Text>
      </Flex>
    </Flex>
  );
};

export const TotalPriceComponent: FC<
  TotalPriceComponentProps & { isLoading: boolean; priceHt?: number }
> = ({
  priceTtc,
  priceHt,
  color,
  showAcompte,
  isLoading,
  priceHtDiscounted,
}): ReactElement => {
  return (
    <Flex
      alignItems={showAcompte ? "flex-start" : "flex-end"}
      flexDir={showAcompte ? "row" : "column"}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        ((showAcompte && priceTtc) || priceHt) && (
          <>
            <Flex direction="row-reverse">
              {!!priceHtDiscounted && (
                <>
                  <Text
                    color={color ?? colors.gray.darkerMode}
                    variant={showAcompte ? "sm" : "md"}
                    fontWeight="700"
                  >
                    {formatingNumber(priceHtDiscounted, "€")}
                  </Text>
                  <Spacer marginRight={4} />
                </>
              )}

              <Text
                color={color ?? colors.gray.darkerMode}
                variant={
                  showAcompte
                    ? !!priceHtDiscounted
                      ? "sm"
                      : "sm"
                    : !!priceHtDiscounted
                    ? "sm"
                    : "md"
                }
                textDecoration={
                  priceHtDiscounted && priceHtDiscounted > 0
                    ? "line-through"
                    : "none"
                }
                fontWeight="700"
              >
                {formatingNumber((showAcompte ? priceTtc : priceHt)!, "€")}
              </Text>
            </Flex>
            {showAcompte ?? <Text variant="xs">Calcul en temps réel</Text>}
          </>
        )
      )}
    </Flex>
  );
};

export default TotalCard;
